<template>
  <div>
    <article class="page category">
      <div class="content-con">
        <h2 class="page-tit">
          <em>{{ currentProdCateDetail.Name }}</em>
        </h2>
        <section class="filter">
          <section class="filter-menu">
            <a id="btnCategory" class="btn-category"
              ><i class="icon-cate"></i>商品分類</a
            >
            <ul class="menu-category">
              <li v-for="(item, key) in currentProdCateDetail.ChildCates" :key="key">
                <a :href="'Category?prodCateId=' + item.Id">
                  <em>{{ item.Name }}</em>
                </a>
              </li>
            </ul>
          </section>
          <section class="filter-category">
            <ul>
              <li
                v-for="(item, key) in currentProdCateDetail.ChildCates"
                :key="key"
              >
                <a :href="'Category?prodCateId=' + item.Id">
                  {{ item.Name }}
                </a>
              </li>
            </ul>
          </section>
        </section>
        <section class="products">
          <ul class="list-prod">
            <li v-for="(item, key) in CateProduct" :key="key">
              <div class="prod">
                <a
                  :href="'product?productId=' + item.Id"
                  class="prod-photo"
                  :style="
                    'background-image: url(' +
                    GetImageUrl(item.MainImage, '255') +
                    ')'
                  "
                ></a>
                <section class="prod-info">
                  <a :href="'product?productId=' + item.Id" class="prod-name">{{
                    item.Title
                  }}</a>
                  <p class="prod-intro">{{ item.SubTitle }}</p>
                  <p class="prod-price">
                    <span class="price-orignal">{{ item.OriginalPrice }}</span>
                    <span class="price-discount">{{ item.Price }}</span>
                  </p>
                  <!--<a href='{Id}' class='btn-addto'></a>-->
                </section>
              </div>
            </li>
          </ul>
          <section class="pager">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="this.totalSize"
              :page-size="12"
              :current-page.sync="currentPage"
            >
            </el-pagination>
          </section>
        </section>
        <!--/products 产品列表-->
      </div>
    </article>
  </div>
</template>
<style scoped>
.el-pagination.is-background .el-pager li {
  background-color: #fff;
  color: #9e9e9e;
  display: inline-block;
  line-height: 36px;
  min-width: 36px;
  height: 36px;
  margin: 0 0.4em;
  border: 1px solid #fff;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #6a0387;
  color: #fff;
  display: inline-block;
  line-height: 36px;
  min-width: 36px;
  height: 36px;
  margin: 0 0.4em;
  border: 1px solid #6a0387;
}
</style>
<script>
import {
  GetCategoryWithChildByProdCateId,
  GetProductCountByProdCateId,
  GetProductByProdCateId,
  GetBreadCrumbByProdCateId,
} from "@/service/api";
export default {
  name: "Category",
  data() {
    return {
      sortoptions: [
        {
          value: "LastModifyDate|desc",
          label: "最新上架",
        },
        {
          value: "",
          label: "热卖商品",
        },
        {
          value: "Price|asc",
          label: "价格低到高",
        },
        {
          value: "Price|desc",
          label: "价格高到低",
        },
      ],
      sortoptionvalue: "",
      CategoryBreadcrumb: [],
      CateProduct: [],
      //Pagination
      total: 0,
      currentPage: 1,
      totalSize: 0,
      currentProdCateDetail: [],
    };
  },
  beforeMount: function () {
    this.GetProduct();
  },
  mounted: function () {
    GetCategoryWithChildByProdCateId(this.$route.query.prodCateId)
      .then((resp) => {
        this.currentProdCateDetail = resp.data;
      })
      .catch((data) => {
        if (data.status === 401) alert("Error: token is not found;");
        if (data.status === 403) alert("Error: authentication failed;");
      });
    GetBreadCrumbByProdCateId(this.$route.query.prodCateId)
      .then((resp) => {
        this.CategoryBreadcrumb = resp.data;
      })
      .catch((data) => {
        if (data.status === 401) alert("Error: token is not found;");
        if (data.status === 403) alert("Error: authentication failed;");
      });
    //banner 設定尺寸
    $(".banner-cover").addClass("_lvSec");
    // filter 開關
    $("#btnCategory").click(function () {
      var winW = $(window).width();
      if (winW > 768) {
        $(".menu-category").toggle();
        $(".filter-category").toggle();
      } else {
        $(".filter-category").toggle();
      }
    });
    $(".menu-category a").click(function () {
      $(".menu-category a").removeClass("active");
      $(this).addClass("active");
    });
  },
  watch: {
    currentPage: function () {
      this.GetProduct();
    },
    sortoptionvalue: function () {
      this.currentPage = 1;
      this.GetProduct();
    },
    currentProdCateDetail: function () {
      this.$parent.SetWindowTitle(this.currentProdCateDetail.Name);
    },
  },
  methods: {
    GetPaginationData: function () {},
    DateDiffToday: function (productDate) {
      var today = new Date();
      var productd = new Date(productDate);
      var oneDay = 1000 * 60 * 60 * 24;
      var result = Math.round(
        (today.getTime() - productd.getTime()) / oneDay
      ).toFixed(0);
      return result;
    },
    GetProduct: function () {
      GetProductCountByProdCateId(this.$route.query.prodCateId)
        .then((resp) => {
          this.totalSize = resp.data;
        })
        .catch((data) => {
          if (data.status === 401) alert("Error: token is not found;");
          if (data.status === 403) alert("Error: authentication failed;");
        });
      GetProductByProdCateId(
        this.$route.query.prodCateId,
        this.currentPage,
        12,
        this.sortoptionvalue
      )
        .then((resp) => {
          this.CateProduct = resp.data;
        })
        .catch((data) => {
          if (data.status === 401) alert("Error: token is not found;");
          if (data.status === 403) alert("Error: authentication failed;");
        });
    },
    GetImageUrl: function (imgId, size) {
      var d = new Date();
      var n = d.getTime();
      return (
        "https://n2imageservice.azurewebsites.net/api/images/shopmall/" +
        imgId +
        "/" +
        size +
        "/0?" +
        n
      );
    },
  },
};
</script>
